const CONST = {
    
  //serverbase: "https://desa.api.aioforms.aiotech.io/",
  //serverbase: "https://api.aioforms.aiotech.io/",
  //serverbase: "https://qa.api.aioforms.aiotech.io/",
  //serverbase: "http://localhost:5010/",
  serverbase:"https://poc-api.atingo.io/",
  key:'c1855b3cded44f9cbea5451ac5e936fa'
}

export const status = [
  { label: "ACTIVA", value: "-1", },
  { label: "INACTIVA", value: "0", }
]

export const ComparisonOperators = [
  { label: "=", value: "=", },
  { label: ">", value: ">", },
  { label: "<", value: "<", },
  { label: ">=", value: ">=", },
  { label: "<=", value: "<=", },
]

export const excusas = [
  { value: "Excusa_Calamidad doméstica", label: "Calamidad doméstica" },
  { value: "Excusa_Cita médica", label: "Cita médica" },
  { value: "Excusa_Comisión", label: "Comisión" },
  { value: "Excusa_Compensado", label: "Compensado" },
  { value: "Excusa_Control Periodico", label: "Control Periodico" },
  { value: "Excusa_Descanso", label: "Descanso" },
  { value: "Excusa_En otra sesión", label: "En otra sesión" },
  { value: "Excusa_En Paradas de Planta", label: "En Paradas de Planta" },
  { value: "Excusa_En turno", label: "En turno" },
  { value: "Excusa_Fuera de Turno", label: "Fuera de Turno" },
  { value: "Excusa_Incapacidad médica", label: "Incapacidad médica" },
  { value: "Excusa_Permiso", label: "Permiso" },
  { value: "Excusa_Vacaciones", label: "Vacaciones" }
]

///States
export const states = [
  { label: "Atrasado", value: "0", bgcolor: "warning" },
  { label: "Vigente", value: "1", bgcolor: "dark" },
  { label: "Cerrado", value: "2", bgcolor: "info" },
  { label: "Cumpliendo", value: "3", bgcolor: "success" },
  { label: "Vencido", value: "4", bgcolor: "danger" }
]

export const RAM = [
  { label: "L", value: "L" },
  { label: "N", value: "N" },
  { label: "M", value: "M" },
  { label: "H", value: "H" },
  { label: "VH", value: "VH" }
]

export const periodicity = [
  { label: "Manual", value: 0 },
  { label: "7 días", value: 7 },
  { label: "14 días", value: 14 },
  { label: "21 días", value: 21 },
  { label: "28 días", value: 28 },
]

export default CONST;
