import { useState } from "react"
import React from 'react'
import {
    Row,
    Col,
  } from "reactstrap";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { requestFile } from "./utils/http/axios";

let ctx = null;
let ctx_modal = null;
const Input = styled('input')({
    display: 'none',
  });

const sendInvoice = (instance, data) => 
    new Promise(resolve => {
        let remote = {
            url: {
                method: "post",
                path: "process_cc",
                auth: {
                    username: 'janedoe',
                    password: 's00pers3cret'
                  },
                data:data
            }
        }

    requestFile(remote.url, instance).then(result => {
        resolve(result)
    })

})


const CamaraC = () => {
 
    const [selectedFiles, setSelectedFiles] = useState([])
    const [step, setStep] = useState(0)
    const [isProcesar, setIsProcesar] = useState(false)
    const [result, setResult] = useState(null)
  

    const onLoad = async(data) => {
        let filesProcs = [];
        let docs_files = [];
        if (data == null){
            let data_form = new FormData();
            setIsProcesar(true)
            setStep(2)
            for (let i = 0; i < selectedFiles.length ;i++){
                let item_a = selectedFiles[i]
                data_form.append("files", item_a, item_a.name);
            }
            
            let loaded_info = await sendInvoice(null, data_form);
            alert(loaded_info.message)

            setResult(loaded_info.result)
            setIsProcesar(false)
        }
    }

    const fileData = () => {
    
        if (selectedFiles.length > 0) {
           
          return (
            <div>
              <h4>Archivo:</h4>
              <ul>
              {selectedFiles.map((item_file, key_file) => (
                <li>{item_file.name}</li>
              ))}
              </ul>
   
            </div>
          );
        } else {
          return (
            <div>
              <h4 style={{color:"#2a3139"}}>Seleccione al menos un Archivo antes de presionar el Boton Procesar</h4>
            </div>
          );
        }
      };

    const onFileChange = event => {
      console.log("filedd")
        let selectedFiles = []
        for(let i=0; i < event.target.files.length;i++)
        {
            selectedFiles.push(event.target.files[i])
        }
        // Update the state
        setStep(1)
        setSelectedFiles(selectedFiles)
      };


        return (
            <div>
{/*                 <div className="header bg-gradient-info">
                
                    <Row>
                        <Col style={{paddingTop:"100px"}}>
                        
                        </Col>
                </Row>
                </div> */}
            
                <Row style={{width:"100%"}}>
                    <Col style={{width:"100%"}}>
                        <div style={{width:"80%", alignContent:"center", alignItems:"center", marginLeft:"auto", marginRight:"auto"}}>
                            <div>
                                <h2 style={{fontFamily:'"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif', color:"#2b7dbc", textAlign:"center",  marginTop:20,alignSelf:"center",marginLeft:"auto", marginRight:"auto"}}>
                                    Seleccione La Camara de Comercio a Procesar
                                </h2>
                                <div style={{marginTop:40, display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center", alignSelf:"center",marginLeft:"auto", marginRight:"auto"}}>
                                    <label htmlFor="contained-button-file">
                                        <Input  style={{ display: 'none' }} accept="application/pdf" type="file" id="contained-button-file"  onChange={onFileChange} />
                                        <Button style={{backgroundColor:"#4f99c6"}} variant="contained" component="span">
                                            Cargar Archivo
                                        </Button>
                                    </label>
                                    {fileData()}
                                    <Button  disabled={step <=  0 || step >=2} style={{color:"white", fontWeight:"bold",backgroundColor:(step <=  0) ? "#629b58" : (step == 2) ? "#91ad63" :"#4f99c6", marginTop:30, width:"60%"}} onClick={(e) => onLoad()}>
                                        {isProcesar && (
                                                <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                                />
                                            )}
                                        {isProcesar ? "Procesando" : "Procesar"}
                                    </Button>
                                </div>  
                            </div>
                        </div>
                        {result != null ?
                            <div style={{marginLeft:200, marginRight:200, marginTop:60}}>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <label style={{fontSize:14, fontWeight:"bold"}}>No de Paginas:</label><label style={{marginLeft:20}}>{result.noPages}</label>
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <label style={{fontSize:14, fontWeight:"bold"}}>No de Lineas:</label><label style={{marginLeft:20}}>{result.noLines}</label>
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <label style={{fontSize:14, fontWeight:"bold"}}>NIT:</label><label style={{marginLeft:20}}>{result.strNIT}</label>
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <label style={{fontSize:14, fontWeight:"bold"}}>Nombre:</label><label style={{marginLeft:20}}>{result.strNombre}</label>
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <label style={{fontSize:14, fontWeight:"bold"}}>Matricula:</label><label style={{marginLeft:20}}>{result.strMatricula}</label>
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <label style={{fontSize:14, fontWeight:"bold"}}>Renovacion de Matricula:</label><label style={{marginLeft:20}}>{result.strRenMatricula}</label>
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <label style={{fontSize:14, fontWeight:"bold"}}>Nombramientos:</label><label style={{marginLeft:20}}>{result.strNombramiento}</label>
                                </div>
                                {result.isRenovacion ?
                                    <div style={{display:"flex", flexDirection:"row"}}>
                                        <label style={{fontSize:16, fontWeight:"bold", color:"red"}}>La Fecha de Renovacion esta Vencida</label>
                                    </div>
                                :null}
                            </div>
                        : null}
                    </Col>
                </Row>
            </div>
        )

}

export default CamaraC;
