import axios from "axios";
import CONST from "../../CONT";
//import { token } from "../../modules/Login/Views/ViewLogin"
const token = "";
async function request(r, i) {
    //const tA = await token(i)
    return await axios({
        method: r.method,
        url: CONST.serverbase + r.path,
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            'Access-Control-Allow-Origin': '*',
            //"Authorization": `Bearer ${tA}`
        },
        params: r.params ? r.params : null,
        data: r.body ? r.body : {},
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
        return err
    })
}

export async function requestFile(r, i) {
    //const tA = await token(i)
    return await axios({
        method: r.method,
        url: CONST.serverbase + r.path,
        headers: {
            "Accept": "*/*",
            'Access-Control-Allow-Origin': '*'
        },
        params: r.params ? r.params : null,
        data: r.data,
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
        return err
    })
}

export async function requestFileExt(r, i) {
    //const tA = await token(i)
    return await axios({
        method: r.method,
        url: r.path,
        headers: {
            "Accept": "*/*",
            'Access-Control-Allow-Origin': '*'
        },
        params: r.params ? r.params : null,
        data: r.data,
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
        return err
    })
}

export async function requestAuth(r, i) {
    return await axios({
        method: r.method,
        url: CONST.serverbase + r.path,
        headers: {
            "Authorization": ""
        },
        auth: r.auth
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
        return err
    })
}

export async function requestFileOLD(r, i) {
    const tA = await token(i)
    return await axios({
        method: r.method,
        url: CONST.serverbase + r.path,
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Ocp-Apim-Subscription-Key": CONST.key,
            "Authorization": `Bearer ${tA}`
        },
        params: r.params ? r.params : null,
        data: r.body ? r.body : {},
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
        return err
    })
}
export default request